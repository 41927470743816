import React from 'react';
import PropTypes from 'prop-types';
import './Statement.css';

const Statement = ({ icon, children }) => (
  <div className="Statement">
    <div className="Statement-logo">{icon}</div>
    <div className="Statement-children">{children}</div>
  </div>
);

Statement.propTypes = {
  icon: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
};

export default Statement;
