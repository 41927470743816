import React from 'react';
// This import pattern lets logo use google fonts
import { ReactComponent as Logo } from './resources/logo-with-text.svg';
import {
  Calendar, Coins, House, IdBadge, Mind, Question, Scroll, Tree,
} from './components/Icons';
import Statement from './components/Statement';
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="App-container">
        <Logo className="App-logo" />
        <div className="App-statement-header">Helping Children to Reach their Full Potential</div>
        <div className="App-statement-wrapper">
          <Statement icon={<House />}>
            1:1 On-line Private Tuition, tailored to support your child’s needs
          </Statement>
          <Statement icon={<IdBadge />}>
            Former Primary School Head with over 30 years state and independent experience
          </Statement>
          <Statement icon={<Mind />}>
            Empathetic and Holistic approach to boost self-confidence and well-being
          </Statement>
          <Statement icon={<Scroll />}>
            Preparation for 11+, Senior School entry exams and KS1, 2, & 3
          </Statement>
          <Statement icon={<Calendar />}>
            Evening and Weekend availability
          </Statement>
          <Statement icon={<Tree />}>
            Holiday Revision sessions to keep skills fresh
          </Statement>
          <Statement icon={<Coins />}>
            Competitive and Affordable Rates
          </Statement>
        </div>
        <div className="App-contact-info-container">
          <div className="App-question-logo"><Question /></div>
          <div className="App-contact-info-lines-container">
            <div className="App-contact-info-name">Heather Hopson-Hill B.Ed. (Hons) Cert Ed</div>
            <div className="App-contact-info-details">
              <a href="mailto:heather@intheschoolroom.co.uk">heather@intheschoolroom.co.uk</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
