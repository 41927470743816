import React from 'react';
import calendar from '../resources/icons/calendar.png';
import coins from '../resources/icons/coins.png';
import house from '../resources/icons/house.png';
import idBadge from '../resources/icons/id-badge.png';
import map from '../resources/icons/map.png';
import mind from '../resources/icons/mind.png';
import question from '../resources/icons/question.png';
import scroll from '../resources/icons/scroll.png';
import tree from '../resources/icons/tree.png';
import './Icons.css';

const Calendar = () => <img src={calendar} className="Icon" alt="Icon of a calendar" />;

const Coins = () => <img src={coins} className="Icon" alt="Icon of a stack of coins" />;

const House = () => <img src={house} className="Icon" alt="Icon of a house" />;

const IdBadge = () => <img src={idBadge} className="Icon" alt="Icon of an identification badge" />;

const Map = () => <img src={map} className="Icon" alt="Icon of map" />;

const Mind = () => <img src={mind} className="Icon" alt="Icon of a head with a brain" />;

const Question = () => <img src={question} className="Icon" alt="Icon of someone asking a question" />;

const Scroll = () => <img src={scroll} className="Icon" alt="Icon of a degree certificate" />;

const Tree = () => <img src={tree} className="Icon" alt="Icon of a palm tree" />;

export {
  Calendar,
  Coins,
  House,
  IdBadge,
  Map,
  Mind,
  Question,
  Scroll,
  Tree,
};
